// Ahladang Team Licence

import React from "react";
import urlController from "../util/urlController";
import {
    Link,
    matchPath,
    useRouteMatch,
} from "react-router-dom";
import dummyCategoriesData from "../util/dummyCategoriesData";

import "../../sass/home/SubMenu.scss";

function SubMenu() {
    const { categorySlug, childCategorySlug } = urlController.getCategorySlugs(useRouteMatch, matchPath);
    const parentCategoryUrl = urlController.toCategoryUrl(categorySlug);
    const isSelectedAll = !childCategorySlug;
    const parentCategory = dummyCategoriesData[categorySlug];
    const menus = parentCategory ? parentCategory.children : [];
    return (
        <div className="home-sub-menu">
            <div className="page-title-below">
                <div className="tasks-view-switcher">
                    <div className="sub-menu">
                        <Link className="all" to={parentCategoryUrl}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill={isSelectedAll ? "#ffffff" : "#ccccccaf"}>
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d={"M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 "
                                    + "12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 "
                                    + ".41 13.41z"} />
                            </svg>
                        </Link>
                        {
                            menus.map((e, i) => {
                                const url = urlController.toCategoryUrl(categorySlug, e.slug);
                                const isSelected = childCategorySlug == e.slug;
                                const color = e.color;
                                const style = {
                                    borderBottom: `2px solid ${color}`,
                                };
                                return <span key={i}>
                                    | <Link
                                        className={`sub-menu-item ${isSelected ? "selected" : ""}`}
                                        to={url}
                                        style={isSelected ? style : {}}
                                    >
                                        {e.name}
                                    </Link>
                                </span>;
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubMenu;
