// Ahladang Team Licence

import React from "react";

import "../../sass/component/LoadFail.scss";

function LoadFail(props) {
    const { onClick, isBrandBg, hide } = props;
    if (hide) {
        return null;
    }

    return (
        <div className={`load-fail-container ${isBrandBg ? "brand-bg" : ""}`}
            onClick={() => {
                if (onClick) {
                    onClick();
                }
            }}>
            <div className="load-fail"></div>
            <div className="load-fail-text">
                <span>ការទាញយក​បរាជ័យ</span>
                <div className="try">ចុចដើម្បីព្យាយាម​ម្តងទៀត</div>
            </div>
        </div>
    );
}

export default LoadFail;
