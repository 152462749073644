// Ahladang Team Licence

import React from "react";
import ReactDOM from "react-dom";
// import * as serviceWorker from "./js/serviceWorker";
import App from "./js/App";

import "./sass/index.scss";

ReactDOM.render(<App />,
    document.getElementById("root")
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// serviceWorker.register();