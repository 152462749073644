// Ahladang Team Licence

import React from "react";
import { Link } from "react-router-dom";
import SearchIcon from "./SearchIcon";
import urlController from "../util/urlController";

import "../../sass/decorator/Footer.scss";
import websiteLogo from "../../images/ahladang.png";
import socialFacebook from "../../images/fb.png";
import socialYouTube from "../../images/yt.png";

const PRIVACY = {
    key: "privacy",
    title: "សិទ្ធិ​នៃ​ការ​ប្រើប្រាស់",
    alertCount: 0,
    description: "Privacy Policy",
    path: "privacy",
    hideOnMobile: true,
};

function Footer(props) {
    const { isSmallScreen } = props;
    return <div className="footer-container">
        <div className="item-left" style={{
            display: "inline-flex",
        }}>
            <Link to={urlController.routeRoot}>
                <img className="logo" src={websiteLogo} alt="logo" />
            </Link>
            <div className="item social">
                <a href="https://www.facebook.com/Ahladang-1096687043695177/"
                    title="អាឡាដាំងលើទំព័រ Facebook"
                    target="_blank">
                    <img src={socialFacebook} alt="Facebook" />
                </a>
            </div>
            <div className="item social">
                <a href="https://www.youtube.com/channel/UC5vd2GxboFzzZpeUEU5zZhQ"
                    title="អាឡាដាំងលើគេហទំព័រ Youtube"
                    target="_blank">
                    <img src={socialYouTube} alt="Youtube" />
                </a>
            </div>
            <div className="item search-icon">
                <SearchIcon isSmallScreen={isSmallScreen} hide={!isSmallScreen} />
            </div>
        </div>
        <div className="item-right">
            <div className="item">
                <a href="https://discuss.ahladang.com"
                    target="_blank" title="Ahladang Forum">
                    <svg xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24" fill="white"
                        width="24px" height="24px">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d={"M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-."
                            + "45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z"} />
                    </svg>
                    {!isSmallScreen && <span>មាតិកាអាឡាដាំង</span>}
                </a>
            </div>
            <div className="item">
                <Link to={urlController.toPageUrl("privacy")} title="Privacy Policy">
                    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24"
                        viewBox="0 0 24 24" fill="white" width="24px"
                        height="24px">
                        <g>
                            <rect fill="none" height="24" width="24" />
                        </g>
                        <g>
                            <g />
                            <g>
                                <path
                                    d={"M21,5l-9-4L3,5v6c0,5.55,3.84,10.74,9,12c2.3-0.56,4.33-1.9,5.88-3.71l-3"
                                        + ".12-3.12c-1.94,1.29-4.58,1.07-6.29-0.64 c-1.95-1.95-1.95-5.12,0-7."
                                        + "07c1.95-1.95,5.12-1.95,7.07,0c1.71,1.71,1.92,4.35,0.64,6.29l2.9,2."
                                        + "9C20.29,15.69,21,13.38,21,11V5z"} />
                                <circle cx="12" cy="12" r="3" />
                            </g>
                        </g>
                    </svg>
                    {
                        !isSmallScreen && <span>សិទ្ធិ​នៃ​ការ​ប្រើប្រាស់</span>
                    }
                </Link>
            </div>
            <div className="item">
                <Link to={urlController.toPageUrl("contact")} title="Contact Us">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"
                        width="24px" height="24px">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path
                            d={"M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2."
                                + "83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2."
                                + "3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 "
                                + "20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"} />
                    </svg>
                    {
                        !isSmallScreen && <span>ទំនាក់ទំនង</span>
                    }
                </Link>
            </div>
        </div>
    </div>;
}

export default Footer;
