// Ahladang Team Licence

import React from "react";
import Card from "./Card";
import LoadFail from "../component/LoadFail";
import ajax from "../util/ajax";
import utils from "../util/utils";
import images from "./images";
import ComponentMounted from "../element/ComponentMounted";

import "../../sass/home/CardView.scss";

function MoreButton({ isLoading, onLoadMore }) {
    const moreButtonClasses = `main-column-add-item-button ${isLoading ? "loading-card" : ""}`;
    return <div className="load-more-container">
        <button className={moreButtonClasses}
            onClick={onLoadMore}>
            {isLoading ? "កំពុង​" : ""}ទាញ​យក​បន្ថែម
        </button>
    </div>;
}

function RenderMainColumnHeader({ cards, category, mix, color }) {
    const image = images[category.slug];
    return <div className={`main-column-header ${mix ? "mix" : ""}`}
        style={{
            background: color,
        }}>
        {
            image && <img className="main-column-total-item-icon"
                src={image} alt={category.slug} />
        }
        <span className="main-column-total-item">
            {cards.length}
        </span>
        <span className="title">
            {category.name}
        </span>
    </div>;
}

class CardView extends ComponentMounted {

    static get COLUMN_WIDTH() {
        return 330;
    }
    static get PER_PAGE() {
        return 12;
    }

    constructor(props) {
        super(props);
        this.state = {
            showingState: ajax.SUCCESS,
            cards: [],
        };
        this.scrollContainer = null;
        this.loadAjax();
    }

    makeCards(props, data) {
        let newCards = data.map(item => {
            const card = utils.transformCard(item, true);
            return card;
        });
        if (props.category.search) {
            return newCards;
        } else {
            const { cards } = this.state;
            newCards = newCards.filter(nItem => {
                return !cards.filter(oItem => {
                    return oItem.id === nItem.id;
                }).length;
            });
            return cards.concat(newCards);
        }
    }

    setShowingState(showingState) {
        this.setStateWhenMounted({ showingState }, () => {
            if (this.props.setShowingState) {
                this.props.setShowingState(showingState);
            }
        });
    }

    loadAjax(props, callback) {
        props = props || this.props;
        const category = props.category;
        if (!category.cardsQuery) {
            return;
        }
        if (typeof callback !== "function") {
            callback = () => { };
        }
        this.setShowingState(ajax.LOADING);
        const { cards } = this.state;
        const offset = cards.length;
        const perPage = props.perPage || CardView.PER_PAGE;
        if (category.search) {
            const apiUrl = `${ajax.API_BASE_JSON_URL}search?_embed&per_page=${perPage}&offset=${offset}&type=post&search=${category.cardsQuery}`;
            const prom = new Promise((resolve, reject) => {
                ajax.get(apiUrl, (ok, data) => {
                    if (ok) {
                        if (category.search) {
                            Promise.all(data.map((d) => {
                                const apiUrl = `${ajax.POSTS_BASE_URL}/${d.id}?_embed`;
                                return new Promise((resolve, reject) => {
                                    ajax.get(apiUrl, (ok, data) => {
                                        if (!ok) {
                                            reject();
                                        } else {
                                            resolve(data);
                                        }
                                    });
                                });
                            })).then(resolve).catch(reject);
                        } else {
                            resolve(data);
                        }
                    } else {
                        return reject(new Error("Fail to load card data"));
                    }
                });
            });
            prom.then((data) => {
                this.setStateWhenMounted({
                    cards: this.makeCards(props, data),
                }, () => {
                    this.setShowingState(ajax.SUCCESS);
                    callback();
                });
            }).catch((err) => {
                this.setShowingState(ajax.FAIL);
            });
        } else {
            const apiUrl = `${ajax.POSTS_BASE_URL}?_embed&per_page=${perPage}&offset=${offset}&${category.cardsQuery}&filter[orderby]=modified&order=desc`;
            let loaded = false;
            ajax.get(apiUrl, (ok, data) => {
                if (!ok) {
                    !loaded && this.setShowingState(ajax.FAIL);
                } else {
                    this.setStateWhenMounted({
                        cards: this.makeCards(props, data),
                    }, () => {
                        this.setShowingState(ajax.SUCCESS);
                        callback();
                    });
                }
                loaded = true;
            }, ["content"]);
        }
    }

    UNSAFE_componentWillReceiveProps(preProps) {
        const { category } = preProps;
        this.setStateWhenMounted({
            cards: [],
        }, () => {
            this.loadAjax(preProps);
        });
    }

    render() {
        const { props, state } = this;
        const { showingState, cards } = state;
        const { color, hideMoreButton } = props;
        const isLoading = showingState === ajax.LOADING;
        const isShowFail = showingState === ajax.FAIL;
        return (
            <div className="main-column fade-in"
                ref={(div) => {
                    this.mainColumn = this.mainColumn || div;
                }} >
                {<RenderMainColumnHeader cards={cards} {...props} />}

                <div className="main-column-body"
                    data-id="2" data-type="column"
                    ref={(self) => {
                        this.scrollContainer = self;
                    }}>
                    <div className="main-column-items-container">
                        {
                            cards.map((card, i) => {
                                const clientWidth = this.mainColumn ? this.mainColumn.clientWidth : CardView.COLUMN_WIDTH;
                                const cardHeight = clientWidth &&
                                    (clientWidth > CardView.COLUMN_WIDTH) ? "200px" : null;
                                return <Card key={i}
                                    color={color}
                                    cardHeight={cardHeight}
                                    card={card}
                                />;
                            })
                        }
                    </div>
                    {!hideMoreButton &&
                        <div className="main-column-subtitle">
                            <div className="main-column-subtitle-box">
                                {isShowFail && !cards.length ?
                                    <LoadFail
                                        isBrandBg
                                        onClick={this.loadAjax.bind(this)}
                                    />
                                    :
                                    <MoreButton
                                        isLoading={isLoading}
                                        onLoadMore={() => {
                                            if (!isLoading) {
                                                this.loadAjax(props, () => {
                                                    utils.scrollToBottom(this.scrollContainer);
                                                });
                                            }
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    }
                </div>
            </div >
        );
    }
}

export default CardView;
