// Ahladang Team Licence

import React from "react";
import CardView from "./home/CardView";

import "../sass/CardList.scss";

function CardList(props) {
    const { perPage, categories, selectedCategory } = props;
    let nWidth = 1;
    const parentWidth = window.innerWidth;
    while ((nWidth < categories.length || categories.length === 1) &&
        CardView.COLUMN_WIDTH * (nWidth + 1) < parentWidth) {
        nWidth++;
    }
    return (
        <div className="card-list fade-in">
            <div className="post-card-view">
                <div className="main-grid">
                    {selectedCategory ?
                        <CardView
                            category={selectedCategory}
                            perPage={perPage}
                            color={selectedCategory.color}
                        /> :
                        <CardView
                            category={{
                                name: "អត្ថបទ​ចំរុះ",
                                cardsQuery: categories.reduce((value, item) => {
                                    return `${value ? value + "&" : ""}categories[]=${item.id}`;
                                }, ""),
                            }}
                            mix
                            perPage={perPage}
                            color="#3ea4d0"
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default CardList;
