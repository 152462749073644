// Ahladang Team Licence

import React from "react";

import "../../sass/component/Loading.scss";

function Loading(props) {
    const { hide } = props;
    return (
        <div className="loading-container" style={hide ? {
            display: "none",
        } : {}}>
            <div className="loading"></div>
            <div id="loading-text">កំពុងទាញ</div>
        </div>
    );
}

export default Loading;
