// Ahladang Team Licence

import React, { useState } from "react";
import { matchPath, useHistory } from "react-router";
import urlController from "../util/urlController";
import { Link } from "react-router-dom";

import "../../sass/decorator/SearchIcon.scss";

function SearchIcon(props) {
    if (props.hide) {
        return null;
    }

    const [n, setN] = useState();

    const history = useHistory();
    history.listen((location, action) => {
        setN(0);
    });

    const url = urlController.toSearchUrl();

    const isSearching = urlController.isSearching(matchPath);
    if (isSearching) {
        return null;
    }
    const isSmallScreen = props.isSmallScreen;
    return (
        <Link className="search-anchor" to={url} title="search posts">
            {isSmallScreen ?
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="36px" height="36px">
                    <path d={"M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 "
                        + "5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 "
                        + "11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"} />
                    <path d="M0 0h24v24H0z" fill="none" />
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="48" height="48"
                    viewBox="0 0 48 48"
                    className="search-icon">
                    <path
                        d={"M19.29,33.29A13.66,13.66,0,1,1,33,19.63,13.68,13.68,0,0,1,19.29,33.29Zm0-23.13a9.46,9.46,0,"
                            + "1,0,9.46,9.46A9.47,9.47,0,0,0,19.29,10.17Z"}
                        transform="translate(-5.63 -5.97)" />
                    <path d={"M40.27,42a2.09,2.09,0,0,1-1.49-.62L29.3,31.94a2.1,2.1,0,0,1,3-3l9.48,9.48A2.1,2.1,0,0,1,"
                        + "40.27,42Z"}
                        transform="translate(-5.63 -5.97)" />
                </svg>
            }
        </Link>
    );
}

export default SearchIcon;
