// Ahladang Team Licence

import React from "react";
import { Link } from "react-router-dom";
import ajax from "../util/ajax";
import Loading from "../component/Loading";
import PostBy from "../decorator/PostBy";
import LoadFail from "../component/LoadFail";
import urlController from "../util/urlController";
import dummyCategoriesData, { childCategoryObjects } from "../util/dummyCategoriesData";
import utils from "../util/utils";
import ComponentMounted from "../element/ComponentMounted";

import fbShare from "../../images/fb-icon-share.png";
import "../../sass/detail/Detail.scss";

function RenderBreadcrumb({ detailData, isPage, pageTitle }) {
    if (detailData == null) {
        return null;
    }

    let categoryList = [];
    const categories = detailData.categories || [];
    categoryList = getDetailCategory(categories);
    return <div className="post-breadcrumb">
        <ol className="breadcrumb breadcrumb-arrow">
            <li>
                <Link to={urlController.routeRoot}>ទំព័រដើម</Link>
            </li>
            {
                categoryList.map((categories, id) => {
                    return categories.map((category, ind) => {
                        let path = category.path;
                        let childPath = null;
                        if (category.parentPath) {
                            childPath = path;
                            path = category.parentPath;
                        }
                        const url = urlController.toCategoryUrl(path, childPath);
                        return <li key={ind}>
                            <Link to={url}>
                                <span style={{
                                    textShadow: `1px 1px 1px ${category.color}`,
                                }}>{category.name}</span>
                            </Link>
                        </li>;
                    });
                })
            }
            <li className="active">
                {
                    isPage ?
                        <span>{pageTitle}</span>
                        :
                        <span>
                            <PostBy
                                authorNames={detailData.authorNames}
                                date={detailData.date}
                            />
                        </span>
                }
            </li>
        </ol>
    </div>;
}

function seekCategoryParents(categories) {
    let parentKeys = categories.map(category => {
        const child = childCategoryObjects[category.slug];
        return child ? child.key : null;
    }).filter(key => !!key);
    parentKeys = utils.uniqueArray(parentKeys);
    const parents = parentKeys.map(key => {
        const parentCategory = dummyCategoriesData[key];
        return {
            name: parentCategory.name,
            slug: parentCategory.slug,
            path: parentCategory.path,
            color: parentCategory.color,
        };
    });
    const childParent = {};
    categories.forEach(category => {
        const match = childCategoryObjects[category.slug];
        if (match) {
            childParent[category.slug] = {
                slug: match.parentSlug,
                path: match.parentPath,
            };
        }
    });
    return {
        parentCategories: parents,
        childParent,
    };
}

function getDetailCategory(categories) {
    let categoryList = [];
    categories = categories.map(category => {
        const child = childCategoryObjects[category.slug];
        return {
            name: category.name,
            slug: category.slug,
            path: category.slug,
            color: child ? child.color : "white",
        };
    });
    categoryList = [
        categories,
    ];
    const { parentCategories, childParent } = seekCategoryParents(categoryList[0]);
    categories = categories.map(category => {
        const parent = childParent[category.slug];
        category["parentSlug"] = parent ? parent.slug : null;
        category["parentPath"] = parent ? parent.path : null;
        return category;
    });
    if (parentCategories.length) {
        categoryList = [parentCategories].concat(categoryList);
    }
    return categoryList;
}

class Detail extends ComponentMounted {

    constructor(props) {
        super(props);
        this.state = {
            apiUrl: "",
            detailData: null,
        };
        this.loadAjax();
    }

    loadAjax(props) {
        props = props || this.props;
        const { apiUrl } = props;
        if (!apiUrl || apiUrl == this.state.apiUrl) {
            return;
        }
        let loaded = false;
        this.setStateWhenMounted({
            showingState: ajax.LOADING,
            apiUrl,
            detailData: null,
        });
        ajax.get(apiUrl, (ok, data) => {
            if (!ok) {
                if (!loaded) {
                    this.setStateWhenMounted({
                        showingState: ajax.FAIL,
                        apiUrl: "",
                    });
                }
            } else {
                const card = utils.transformCard(data);
                card.body = ajax.replaceBaseURL(card.body);
                this.setStateWhenMounted({
                    showingState: ajax.SUCCESS,
                    detailData: card,
                });
            }
            loaded = true;
        });
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.loadAjax(props);
    }

    render() {
        const { isPage, pagePath, postId } = this.props;
        let shareUrl = "";
        if (isPage) {
            shareUrl = `${ajax.API_BASE_URL}/${pagePath}`;
        } else {
            const routePost = window.urlControllerOptions.routePost;
            const path = routePost.replace(":postId", postId);
            shareUrl = `${ajax.API_BASE_URL}${path}`;
        }

        const { detailData, showingState } = this.state;
        let imgSrc = null;
        if (detailData != null) {
            imgSrc = detailData.imgSrc["large"];
        }

        const isLoading = showingState === ajax.LOADING;
        const isShowFail = showingState === ajax.FAIL;
        const isShowDetail = detailData != null && showingState === ajax.SUCCESS;
        return (
            <div className="post-container">
                <Loading hide={!isLoading} />

                {isShowFail && <LoadFail onClick={this.loadAjax.bind(this)} />}

                {isShowDetail &&
                    <div className="post-view">
                        <div className="post-header">
                            <RenderBreadcrumb detailData={detailData} {...this.props} />
                            <div className="title-container">
                                <span className="post-title" dangerouslySetInnerHTML={{
                                    __html: detailData.title,
                                }} />
                            </div>
                            <div className="feature-image-container">
                                {
                                    imgSrc && <img src={imgSrc} alt="featured" />
                                }
                            </div>
                        </div>
                        <div className="entry-content"
                            dangerouslySetInnerHTML={{
                                __html: detailData.body,
                            }} />
                        <div className="tools">
                            <div className="share-fb">
                                <div className="share-fb">
                                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
                                        target="_blank">
                                        <img src={fbShare} alt="Share to Facebook" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Detail;

