// Ahladang Team Licence

import React from "react";
import { Link } from "react-router-dom";
import urlController from "../util/urlController";

import "../../sass/decorator/Logo.scss";

function Logo(props) {
    const { isHome, isSmallScreen } = props;
    if (isSmallScreen) {
        return null;
    }
    return (
        <Link to={urlController.routeRoot} title="ទំព័រដើម">
            <div className={`logo ${!isHome ? "pulse" : ""}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    fill="white" width="48px" height="48px">
                    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                </svg>
            </div>
        </Link>
    );
}

export default Logo;
