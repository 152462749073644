// Ahladang Team Licence

import React from "react";
import moment from "moment-with-locales-es6";
import Moment from "react-moment";

Moment.globalMoment = moment;
// Set the locale for every react-moment instance to French.
Moment.globalLocale = "km";

function PostBy(props) {

    const { authorNames, date } = props;
    return <>
        អត្ថបទដោយ <span className="author">{authorNames}
        </span> នៅ <span className="time">
            <Moment toNow ago date={date}></Moment>
        </span> កន្លងទៅ
    </>;
}

export default PostBy;
