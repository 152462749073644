"use strict";

/**
 * baseUrl: "http://ahladang.local",
 * apiBasePath: "/wp-json/wp/v2",
 * post: "/posts/:date/:postId",
 * parentCategory: "/categories/:categorySlug",
 * category: "/categories/:parentCategorySlug/:categorySlug",
 * tag: "/tags/:tagSlug",
 * search: "/search/?s=searchPattern",
 * page: "*",
 */
class URLController {
    constructor(options) {
        this.baseUrl = options.baseUrl;
        this.apiBasePath = options.apiBasePath;
        this.routePost = options.routePost;
        this.routeCategory = options.routeCategory;
        this.routeParentCategory = options.routeParentCategory;
        this.routeTag = options.routeTag;
        this.routeSearch = options.routeSearch;
        this.routePage = options.routePage;
        this.routeRoot = "/";
    }
    allRoutes() {
        return [
            { pathName: this.routePost, example: "/posts/2020-04/12" },
            { pathName: this.routeCategory, example: "/categories/knowledge/grade12" },
            { pathName: this.routeParentCategory, example: "/categories/scholarship" },
            { pathName: this.routeTag, example: "/tags/tag1" },
            { pathName: this.routeSearch, example: "/search/?s=hello" },
            { pathName: this.routePage, example: "/about" },
        ];
    }

    toCategoryUrl(categorySlug, childCategorySlug) {
        let route = childCategorySlug ? this.routeCategory : this.routeParentCategory;
        route = route.replace(":categorySlug", categorySlug);
        if (childCategorySlug) {
            route = route.replace(":childCategorySlug", childCategorySlug);
        }
        return route;
    }
    toPostUrl(postId) {
        let route = this.routePost;
        route = route.replace(":postId", postId);
        return route;
    }
    toSearchUrl(searchPattern) {
        const url = new URL(`${this.baseUrl}${this.routeSearch}`);
        const searchParams = url.searchParams;
        searchParams.set("s", searchPattern);

        return url.pathname;
    }
    toPageUrl(page) {
        return `/${page}`;
    }

    toAPIUrl(path) {
        return this.baseUrl + this.apiBasePath + path;
    }
    toPostAPIUrl(date, postId) {
        return this.toAPIUrl("/posts/" + date + "/" + postId);
    }
    toOrphanCategoryAPIUrl(categorySlug) {
        return this.toAPIUrl("/categories/" + categorySlug);
    }
    toCategoryAPIUrl(parentCategorySlug, categorySlug) {
        return this.toAPIUrl("/categories/" + parentCategorySlug + "/" + categorySlug);
    }
    toTagAPIUrl(tagSlug) {
        return this.toAPIUrl("/tags/" + tagSlug);
    }
    toSearchAPIUrl(searchPattern) {
        let url = this.toAPIUrl("");
        url = new URL(url);
        const searchParams = url.searchParams;
        searchParams.set("s", searchPattern);
        return url.toString();
    }
    toPageAPIUrl(path) {
        return this.toAPIUrl("/" + path);
    }

    getCategorySlugs(useRouteMatch, matchPath) {
        const isRoot = this.isRoot(matchPath);
        if (isRoot) {
            return { categorySlug: "scholarship" };
        }
        let match = useRouteMatch(this.routeCategory);
        match = match || useRouteMatch(this.routeParentCategory);
        if (match) {
            const params = match.params;
            return params;
        } else {
            return {};
        }
    }
    getPostId(useRouteMatch) {
        const match = useRouteMatch(this.routePost);
        if (match) {
            const params = match.params;
            return params;
        } else {
            return {};
        }
    }

    isRoot(matchPath) {
        const isRoot = !!matchPath(window.location.pathname, {
            path: ["/", "/index.html"],
            exact: true,
        });
        return isRoot;
    }

    isSearching(matchPath) {
        const match = matchPath(window.location.pathname, {
            path: this.routeSearch,
        });
        const isSearching = !!match;
        return isSearching;
    }

    isReading(useRouteMatch) {
        const { postId } = this.getPostId(useRouteMatch);
        return postId !== undefined;
    }

    routeTitle(matchPath, useRouteMatch) {
        if (this.isRoot(matchPath)) {
            return "ទំព័រដើម";
        }
        if (this.isSearching(matchPath)) {
            return "ស្វែង​រក";
        }
        const isPosts = matchPath(window.location.pathname, {
            path: [
                this.routeCategory,
                this.routeParentCategory,
            ],
        });
        if (isPosts) {
            return "អត្ថបទ​ចំរុះ";
        }
        if (this.isReading(useRouteMatch)) {
            return "អាន​អត្ថបទ";
        }
        return "ទំព័រ​ផ្សេងៗ";
    }
}

const urlController = new URLController(window.urlControllerOptions);

export default urlController;