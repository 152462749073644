// Ahladang Team Licence

import React from "react";

import "../../sass/detail/NotFound.scss";

function NotFound() {
    return <div className="not-found">
        <h2>404</h2>
        <p>មិន​មាន​ទិន្នន័យ</p>
    </div>;
}

export default NotFound;

