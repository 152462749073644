import { Component } from "react";

class ComponentMounted extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
    };

    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    get isMounted() {
        return this._isMounted;
    }

    setStateWhenMounted(state = {}, callback = function () { }) {
        if (this._isMounted) {
            this.setState(state, callback);
        } else {
            if (typeof state === "function") {
                state = state(this.state);
            }
            const keys = Object.keys(state);
            keys.forEach(key => {
                this.state[key] = state[key];
            });
            callback();
        }
    }
}

export default ComponentMounted;