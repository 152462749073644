// Ahladang Team Licence

import React, { useState } from "react";
import { useHistory, useRouteMatch, matchPath } from "react-router";
import SearchIcon from "./SearchIcon";
import Logo from "./Logo";
import MenuTab from "./MenuTab";
import urlController from "../util/urlController";

import "../../sass/decorator/Header.scss";

function Header(props) {
    const [n, setN] = useState();

    const history = useHistory();
    history.listen((location, action) => {
        setN(0);
    });

    const isSmallScreen = props.isSmallScreen;
    const { categorySlug } = urlController.getCategorySlugs(useRouteMatch, matchPath);
    const isHome = urlController.isRoot(matchPath);

    return (
        <div className="header-container">
            <div className="logo-container">
                <Logo
                    isSmallScreen={isSmallScreen}
                    isHome={isHome}
                />
            </div>
            <div className="search-icon-container">
                <SearchIcon
                    isSmallScreen={isSmallScreen}
                    hide={isSmallScreen}
                />
            </div>
            <MenuTab categorySlug={categorySlug} />
        </div>
    );
}

export default Header;
