// Ahladang Team Licence

import React from "react";
import { Link } from "react-router-dom";
import urlController from "../util/urlController";
import utils from "../util/utils";
import dummyCategoriesData from "../util/dummyCategoriesData";

import "../../sass/decorator/MenuTab.scss";

const LI_WIDTH = 115;

function MenuTab(props) {
    const menus = utils.objValues(dummyCategoriesData);
    const { categorySlug } = props;
    const selectedCate = menus.find(menu => menu.slug == categorySlug);;
    const selectedCateInd = menus.indexOf(selectedCate);

    const sliderStyle = selectedCate ? {
        left: `${selectedCateInd * LI_WIDTH}px`,
        width: `${LI_WIDTH}px`,
    } : { display: "none" };
    return (
        <div className="menu-container">
            <ul style={{
                maxWidth: `${menus.length * LI_WIDTH}px`,
            }}>
                {
                    menus.map((menu, i) => {
                        const selected = menu.slug == categorySlug;
                        const url = urlController.toCategoryUrl(menu.path);
                        return <li key={i}
                            className={`${selected ? "selected" : ""}`}
                            style={{
                                width: `${LI_WIDTH}px`,
                                left: `${i * LI_WIDTH}px`,
                            }}>
                            <Link to={url}>
                                <span>{menu.name}</span>
                                {
                                    menu.alertCount > 0 && <span className="alert-count">
                                        {menu.alertCount}
                                    </span>
                                }
                            </Link>
                        </li>;
                    })
                }
                <li className="slider" style={sliderStyle} />
            </ul>
        </div>
    );
}

export default MenuTab;
