import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Paper,
    InputBase,
    IconButton,
    Divider,
} from "@material-ui/core";
import {
    Close as CloseIcon,
    Search as SearchIcon,
    Home as HomeIcon,
} from "@material-ui/icons";
import LinkNoDecoration from "./LinkNoDecoration";
import urlController from "../util/urlController";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        maxWidth: 400,
        borderRadius: 0,
        margin: "auto",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    iconButton: {
        padding: 10,
    },
}));

function SearchingCard({ onSearch, defaultPattern, inputName }) {
    const [value, setValue] = useState(defaultPattern || "");
    const classes = useStyles();
    const onChange = (e) => {
        setValue(e.target.value);
    };
    const clearInput = () => {
        setValue("");
    };

    return (
        <Paper component="form" className={classes.root}>
            <LinkNoDecoration to={urlController.routeRoot}>
                <IconButton color="primary"
                    className={classes.iconButton}
                    aria-label="home">
                    <HomeIcon />
                </IconButton>
            </LinkNoDecoration>
            <Divider className={classes.divider}
                orientation="vertical" />
            <InputBase
                className={classes.input}
                placeholder="ពាក្យ​ដើម្បី​ស្វែង​រក"
                name={inputName}
                inputProps={{
                    "aria-label": "search",
                    value,
                    onChange,
                }}
            />
            <IconButton
                disabled={!value}
                style={{ visibility: !value ? "hidden" : "visible" }}
                className={classes.iconButton}
                onClick={clearInput}
                aria-label="clear">
                <CloseIcon color="secondary" />
            </IconButton>
            <IconButton
                disabled={!value}
                onClick={() => {
                    onSearch(value);
                }}
                className={classes.iconButton}
                aria-label="search">
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}

export default SearchingCard;