// Ahladang Team Licence

import React from "react";
import { useRouteMatch, matchPath } from "react-router-dom";
import CardList from "./CardList";
import dummyCategoriesData from "./util/dummyCategoriesData";
import urlController from "./util/urlController";

function Posts() {
    const {
        categorySlug,
        childCategorySlug,
    } = urlController.getCategorySlugs(useRouteMatch, matchPath);
    const parentCategory = dummyCategoriesData[categorySlug];
    const categories = parentCategory ? parentCategory.children : [];

    let selectedCategory = null;
    if (childCategorySlug) {
        selectedCategory = categories.find(category => {
            return category.slug == childCategorySlug;
        });
    }

    return <CardList
        categories={categories}
        selectedCategory={selectedCategory} />;
}

export default Posts;
