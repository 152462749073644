// Ahladang Team Licenceconst

const utils = {
    addEvent(object, type, callback) {
        if (object === null || typeof (object) === "undefined") { return; }
        if (object.addEventListener) {
            object.addEventListener(type, callback, false);
        } else if (object.attachEvent) {
            object.attachEvent("on" + type, callback);
        } else {
            object["on" + type] = callback;
        }
    },

    scrollTo(element, top) {
        if (!element || typeof element.scroll !== "function") { return; }
        element.scroll({
            top,
            left: 0,
            behavior: "smooth",
        });

    },
    scrollToBottom(element) {
        if (!element) { return; }
        this.scrollTo(element, element.scrollHeight);
    },
    scrollToTop(element) {
        if (!element) { return; }
        this.scrollTo(element, 0);
    },
    scrollToView(element) {
        if (!element) { return; }
        element.scrollIntoView({
            behavior: "smooth",
        });
    },
    allElementOfObject(obj) {
        const keys = Object.keys(obj);
        const elements = keys.reduce((total, key) => {
            return total.concat(obj[key]);
        }, []);
        return elements;
    },
    objValues(obj) {
        const keys = Object.keys(obj);
        const values = keys.map(key => obj[key]);
        return values;
    },
    uniqueArray(arr) {
        return arr.filter((v, i, total) => {
            return total.indexOf(v) === i;
        });
    },

    // https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433
    isElementInViewport(el) {
        if (!el) {
            return;
        }
        // Special bonus for those using jQuery
        if (typeof jQuery === "function" && el instanceof jQuery) {
            el = el[0];
        }

        var rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    },
    onVisibilityChange(el, callback) {
        var old_visible;
        return function () {
            var visible = this.isElementInViewport(el);
            if (visible != old_visible) {
                old_visible = visible;
                if (typeof callback == "function") {
                    callback();
                }
            }
        }.bind(this);
    },
    listenElementInterViewport(el, cb) {
        var handler = this.onVisibilityChange(el, function () {
            cb();
        });

        // jQuery
        // $(window).on('DOMContentLoaded load resize scroll', handler);

        // Non-jQuery
        if (window.addEventListener) {
            addEventListener("DOMContentLoaded", handler, false);
            addEventListener("load", handler, false);
            addEventListener("scroll", handler, false);
            addEventListener("resize", handler, false);
        } else if (window.attachEvent) {
            attachEvent("onDOMContentLoaded", handler); // Internet Explorer 9+ :(
            attachEvent("onload", handler);
            attachEvent("onscroll", handler);
            attachEvent("onresize", handler);
        }
    },
    transformCard(originalCard, isMinimize) {
        const embedded = originalCard._embedded;
        const authorNames = embedded.author.map(e => e.name);
        const imgSrc = {};
        try {
            const imageSizes = embedded["wp:featuredmedia"][0]["media_details"]["sizes"];
            const keys = Object.keys(imageSizes);
            keys.forEach(key => {
                imgSrc[key] = imageSizes[key]["source_url"];
            });
        } catch (error) { }
        let categories = [];
        try {
            categories = embedded["wp:term"][0].map(e => {
                const d = {
                    name: e.name,
                    slug: e.slug,
                };
                return d;
            });
        } catch (error) { }
        const card = {
            title: originalCard.title.rendered,
            id: originalCard.id,
            date: originalCard.date,
            authorNames: authorNames.join(", "),
            imgSrc,
            categories,
        };
        if (!isMinimize) {
            card.body = originalCard.content.rendered;
        }
        return card;
    },
    isProduction() {
        const isProd = process.env.NODE_ENV === "production";
        return isProd;
    },
    stopClickEvent(e) {
        e.stopPropagation && e.stopPropagation();
        e.preventDefault && e.preventDefault();
        const nativeEvent = e.nativeEvent;
        if (nativeEvent) {
            nativeEvent.stopPropagation();
            nativeEvent.preventDefault();
        }
    },
};
window.utils = utils;
export default utils;