// Ahladang Team Licence

import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import CardView from "./home/CardView";
import urlController from "./util/urlController";
import SearchingCard from "./element/SearchingCard";

import "../sass/Search.scss";

function useQuery() {
    const location = useLocation();
    return new URLSearchParams(location.search);
}

function Search() {
    const history = useHistory();
    const query = useQuery();
    const inputName = "s";
    const searchPattern = query.get(inputName);

    return <div className="search-container">
        <div className="search-box-container">
            <SearchingCard
                inputName={inputName}
                defaultPattern={searchPattern}
                onSearch={(pattern) => {
                    history.push(`${urlController.routeSearch}?${inputName}=${pattern}`);
                }} />
        </div>

        <div className="card-view-container">
            <CardView
                category={{
                    name: "អត្ថបទរកឃើញ",
                    cardsQuery: searchPattern,
                    search: true,
                }}
                mix
                color="#3ea4d0"
            />
        </div>
    </div>;
}

export default Search;
