// Ahladang Team Licence

import React from "react";
import CardList from "./CardList";
import { categories } from "./util/dummyCategoriesData";

function Home() {
    return <CardList
        categories={categories}
        perPage={12} />;
}

export default Home;
