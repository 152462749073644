import React from "react";
import { Link } from "react-router-dom";

function LinkNoDecoration(props) {
    const { onClick, to, title, children } = props;
    return <Link
        to={to}
        title={title}
        onClick={(e) => {
            const reg = new RegExp(`.*${to}$`);
            if (!!window.location.href.match(reg)) {
                window.location.reload();
            }
            if (onClick) {
                onClick(e);
            }
        }}
        style={{
            textDecoration: "none",
        }}>
        {children}
    </Link>;
}

export default LinkNoDecoration;