// Ahladang Team Licence

import React from "react";
import ajax from "./util/ajax";
import utils from "./util/utils";
import ComponentMounted from "./element/ComponentMounted";

import "../sass/News.scss";

class News extends ComponentMounted {
    constructor(props) {
        super(props);
        this.state = {
            apiUrl: "",
            data: [],
        };
        this.loadAjax();
    }

    loadAjax() {
        const apiUrl = `${ajax.API_BASE_JSON_URL}pages?search=news%3A+&_embed`;
        if (apiUrl == this.state.apiUrl) {
            return;
        }
        let loaded = false;
        this.setStateWhenMounted({
            apiUrl,
            data: [],
        });
        ajax.get(apiUrl, (ok, data) => {
            if (!ok) {
                if (!loaded) {
                    this.setStateWhenMounted({
                        apiUrl: "",
                    });
                }
            } else {
                data = data.map(d => {
                    const card = utils.transformCard(d);
                    card.body = ajax.replaceBaseURL(card.body);
                    return card;
                });
                this.setStateWhenMounted({
                    data,
                });
            }
            loaded = true;
        });
    }

    render() {
        const { data } = this.state;
        const style = this.props.style || {};
        return <div id="news" style={style}>
            {
                data.map((d, ind) => {
                    return <div key={ind} className="detail-container fade-in">
                        <div className="entry-content"
                            dangerouslySetInnerHTML={{
                                __html: d.body,
                            }} />
                    </div>;
                })
            }
        </div>;
    }
}

export default News;
