// Ahladang Team Licence

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./Home";
import News from "./News";
import Header from "./decorator/Header";
import Footer from "./decorator/Footer";
import Posts from "./Posts";
import Post from "./detail/Post";
import SearchBox from "./Search";
import Page from "./detail/Page";
import SubMenu from "./home/SubMenu";
import urlController from "./util/urlController";
import utils from "./util/utils";
import Example from "./example/Example";
import ComponentMounted from "./element/ComponentMounted";

const SMALL_SCREEN_THRESHOLD = 500;

class App extends ComponentMounted {
    constructor(props) {
        super(props);
        this.state = {
            isSmallScreen: false,
        };
        utils.addEvent(window, "resize", this._callForDocumentWidth.bind(this));
        utils.addEvent(window, "load", this._callForDocumentWidth.bind(this));
    }

    _callForDocumentWidth() {
        const parentWidth = window.innerWidth;
        const biggerThanMobile = parentWidth > SMALL_SCREEN_THRESHOLD;
        this.setStateWhenMounted({
            isSmallScreen: !biggerThanMobile,
        });
        return biggerThanMobile;
    }

    componentDidMount() {
        super.componentDidMount();
        this._callForDocumentWidth();
    }

    render() {
        const { isSmallScreen } = this.state;
        return (
            <Router>
                <News />
                <div id="header">
                    <Header isSmallScreen={isSmallScreen} />
                </div>
                <div id="body">
                    <Switch>
                        {!utils.isProduction() &&
                            <Route exact path="/example/:id" >
                                <Example />
                            </Route>
                        }
                        <Route exact path={urlController.routeRoot} >
                            <Home />
                        </Route>
                        <Route path={[
                            urlController.routeCategory,
                            urlController.routeParentCategory,
                        ]} >
                            <SubMenu />
                            <Posts />
                        </Route>
                        <Route path={urlController.routePost} >
                            <Post />
                        </Route>
                        <Route path={urlController.routeSearch} >
                            <SearchBox />
                        </Route>
                        <Route path={urlController.routePage} >
                            <Page />
                        </Route>
                    </Switch>
                </div>
                <div id="footer">
                    <Footer isSmallScreen={isSmallScreen} />
                </div>
            </Router>
        );
    }
}

export default App;