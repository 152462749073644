// Ahladang Team Licence

import React, { useState } from "react";
import { useHistory } from "react-router";
import Detail from "./Detail";
import NotFound from "./NotFound";
import ajax from "../util/ajax";

const PAGE_ID = [
    {
        path: "privacy", id: 334, title: "សិទ្ធិនៃការប្រើប្រាស់",
    },
    {
        path: "contact", id: 148, title: "ទំនាក់ទំនង",
    },
];

function Page() {
    const [n, setN] = useState();

    const history = useHistory();
    history.listen((location, action) => {
        setN(0);
    });

    const pathname = window.location.pathname;
    const page = PAGE_ID.find(e => {
        return pathname.indexOf(`/${e.path}`) == 0;
    });

    if (page) {
        const apiUrl = `${ajax.API_BASE_JSON_URL}pages/${page.id}?_embed`;
        return <Detail
            apiUrl={apiUrl}
            isPage
            pagePath={page.path}
            pageTitle={page.title}
        />;
    } else {
        return <NotFound />;
    }
}

export default Page;

