// Ahladang Team Licence

const dummyCategoriesData = {
    knowledge: {
        name: "អប់រំទូទៅ",
        slug: "knowledge",
        path: "knowledge",
        color: "#d3d3d3",
        children: [
            {
                id: 5,
                cardsQuery: "categories=5",
                name: "ចំនេះដឹង",
                slug: "knowledge",
                path: "knowledge",
                color: "#b88357",
            },
            {
                id: 16,
                cardsQuery: "categories=16",
                name: "កំសាន្ត",
                slug: "entertainment",
                path: "entertainment",
                color: "#be7992",
            },
            {
                id: 15,
                cardsQuery: "categories=15",
                name: "ជីវិត​និង​​សង្គម",
                slug: "life",
                path: "life",
                color: "#7ba0b3",
            },
            {
                id: 92,
                cardsQuery: "categories=92",
                name: "យុវវ័យនិង​ស្នេហា",
                slug: "love",
                path: "love",
                color: "#c282c9",
            },
            {
                id: 4,
                cardsQuery: "categories=4",
                name: "សុខភាព",
                slug: "health",
                path: "health",
                color: "#2e2c52",
            },
            {
                id: 93,
                cardsQuery: "categories=93",
                name: "អន្តរជាតិ",
                slug: "international",
                path: "international",
                color: "#2174ab",
            },
            {
                id: 1,
                cardsQuery: "categories=1",
                name: "កីឡា",
                slug: "sport",
                path: "sport",
                color: "#18b094",
            },
            {
                id: 7,
                cardsQuery: "categories=7",
                name: "បច្ចេកវិទ្យា",
                slug: "technology",
                path: "technology",
                color: "#325c6b",
            },
            {
                id: 59,
                cardsQuery: "categories=59",
                name: "ការអប់រំ",
                slug: "education",
                path: "education",
                color: "#1b70a9",
            },
        ],
    },
    scholarship: {
        name: "អាហារូបករណ៍",
        slug: "scholarship",
        path: "scholarship",
        color: "#d3d3d3",
        children: [
            {
                id: 95,
                cardsQuery: "categories=95",
                name: "អាហារូបករណ៍",
                slug: "scholarship",
                path: "scholarship",
                color: "#95bbeb",
            },
        ],
    },
    language: {
        name: "ភាសាអង់គ្លេស",
        slug: "language",
        path: "language",
        color: "#d3d3d3",
        alertCount: 0,
        children: [
            {
                id: 349,
                cardsQuery: "categories=349",
                name: "ភាសាអង់គ្លេស",
                slug: "english",
                path: "english",
                color: "#b85e65",
            }, {
                id: 405,
                cardsQuery: "categories=405",
                name: "IELTS",
                slug: "ielts",
                path: "ielts",
                color: "#b63863",
            }, {
                id: 337,
                cardsQuery: "categories=337",
                name: "TOEFL",
                slug: "toefl",
                path: "toefl",
                color: "#0a65a7",
            },
        ],
    },
};
const keys = Object.keys(dummyCategoriesData);
export const categories = keys.reduce((total, key) => {
    return total.concat(dummyCategoriesData[key].children);
}, []);

const parentKeys = Object.keys(dummyCategoriesData);
export const childCategoryObjects = {};
parentKeys.forEach(key => {
    const parent = dummyCategoriesData[key];
    parent.children.forEach(child => {
        return childCategoryObjects[child.slug] = {
            key,
            parentSlug: parent.slug,
            parentPath: parent.path,
            color: child.color,
        };
    });
});

export default dummyCategoriesData;