// Ahladang Team Licence

const AHLADANG_URL = window.urlControllerOptions.baseUrl;
const TARGET_URL = window.info ? window.info.targetUrl : AHLADANG_URL;
const API_BASE_URL = window.info ? (window.info.apiBaseURL || window.location.origin) : AHLADANG_URL;
const API_BASE_JSON_URL = API_BASE_URL + "/wp-json/wp/v2/";
const POSTS_BASE_URL = API_BASE_JSON_URL + "posts";
const MEDIA_BASE_URL = API_BASE_JSON_URL + "media";
const ARCHIVE_BASE = "archives";
const ARCHIVE_BASE_URL = API_BASE_URL + "/" + ARCHIVE_BASE;
const CATS_BASE_URL = API_BASE_JSON_URL + "categories";

const REQUEST_TIMEOUT = 1e10;

const ajax = {
    cache: {},
    AHLADANG_URL,
    API_BASE_JSON_URL,
    TARGET_URL,
    API_BASE_URL,
    POSTS_BASE_URL,
    MEDIA_BASE_URL,
    ARCHIVE_BASE,
    ARCHIVE_BASE_URL,
    CATS_BASE_URL,

    LOADING: 0,
    FAIL: 1,
    SUCCESS: 2,
    clearCached() {
        this.cache = {};
    },
    replaceBaseURL(content = "") {
        const newContent = content.replace(new RegExp(this.TARGET_URL, "g"), this.API_BASE_URL);
        return newContent;
    },
    initXmlHttp(method, url) {
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.open(method, url, true);
        return xmlHttp;
    },
    getUrl() {
        return window.SERVER_URL;
    },
    cleanUrl(url) {
        const ind = !!~url.indexOf(this.API_BASE_URL);
        if (ind) {
            return url.substr(this.API_BASE_URL.length, url.length);
        }
        return url;
    },
    get(url, callback, cacheBase) {
        setTimeout(() => {
            callback && callback(false);
            callback = null;
        }, REQUEST_TIMEOUT);
        if (typeof callback !== "function") {
            callback = () => { };
        }
        const data = this.cache[this.cleanUrl(url)];
        if (data) {
            try {
                callback(true, JSON.parse(data));
                callback = null;
                if (cacheBase) {
                    return;
                }
            } catch (e) { }
        }

        const xmlHttp = this.initXmlHttp("GET", url);
        xmlHttp.onreadystatechange = () => {
            if (xmlHttp.readyState === 4) {
                let data = xmlHttp.responseText;
                try {
                    data = JSON.parse(data);
                    this.cache[this.cleanUrl(url)] = JSON.stringify(data);
                } catch (e) { }
                callback && callback(xmlHttp.status === 200, data);
                callback = null;
            }
        };
        xmlHttp.send();
    },
    post(url, data = {}, callback = () => { }) {
        const xmlHttp = this.initXmlHttp("POST", url);
        xmlHttp.setRequestHeader("Content-Type", "application/json");
        xmlHttp.onreadystatechange = () => {
            if (xmlHttp.readyState === 4) {
                let data = xmlHttp.responseText;
                try {
                    data = JSON.parse(data);
                } catch (e) { }
                callback(xmlHttp.status === 200, data);
            }
        };
        xmlHttp.send(JSON.stringify(data));
    },
    getImageUrl() {
        return "";
    },
};

export default ajax;