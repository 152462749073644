/**
 * Author: Raksa Eng
 */
import React from "react";
import {
    useParams,
} from "react-router-dom";
import Example0 from "../element/SearchingCard";
import Example1 from "./Example1";

function Example(props) {
    const { id } = useParams();
    const list = [
        Example0,
        Example1,
    ];
    const ExampleSelected = list[id];
    if (!ExampleSelected) {
        return <div>Not Found</div>;
    }
    return <ExampleSelected />;
}

export default Example;