// Ahladang Team Licence

import React from "react";
import { useRouteMatch } from "react-router-dom";
import Detail from "./Detail";
import urlController from "../util/urlController";
import ajax from "../util/ajax";

function Post() {
    const { postId } = urlController.getPostId(useRouteMatch);
    const apiUrl = `${ajax.POSTS_BASE_URL}/${postId}?_embed`;

    return <Detail apiUrl={apiUrl} postId={postId} />;
}

export default Post;

