// Ahladang Team Licence

import education from "../../images/icons/education.png";
import english from "../../images/icons/english.png";
import entertainment from "../../images/icons/entertainment.png";
import ielts from "../../images/icons/ielts.png";
import international from "../../images/icons/international.png";
import japanese from "../../images/icons/japanese.png";
import knowledge from "../../images/icons/knowledge.png";
import love from "../../images/icons/love.png";
import scholarship from "../../images/icons/scholarship.png";
import life from "../../images/icons/life.png";
import sport from "../../images/icons/sport.png";
import technology from "../../images/icons/technology.png";
import toefl from "../../images/icons/toefl.png";
import youth from "../../images/icons/youth.png";

const images = {
    education, english, entertainment, ielts, international, japanese,
    knowledge, love, scholarship, life, sport, technology, toefl, youth,
};

export default images;
