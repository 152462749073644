// Ahladang Team Licence

import React from "react";
import { Link } from "react-router-dom";
import PostBy from "../decorator/PostBy";
import urlController from "../util/urlController";

import "../../sass/home/Card.scss";

function Card(props) {
    const { card, color, cardHeight } = props;

    const style = {
        borderLeft: `3px solid ${color}`,
    };
    if (cardHeight) {
        style.height = cardHeight;
    }
    const url = urlController.toPostUrl(card.id);

    const categories = card.categories || [];
    const categoryList = categories.map(category => {
        return {
            name: category.name,
            slug: category.slug,
            path: category.slug,
        };
    });
    const imgSrc = card.imgSrc["medium"];
    return (
        <Link className="home-card"
            to={url}
            style={style}>
            <div className="title">
                <span dangerouslySetInnerHTML={{
                    __html: card.title,
                }} />
            </div>
            <hr />
            <div className="content">
                <img src={imgSrc} alt="featured" />
                <span className="text"
                    dangerouslySetInnerHTML={{
                        __html: card.excerpt,
                    }} />
            </div>
            <hr />
            <div className="info-container">
                <div className="category">
                    {categoryList.map(category => category.name).join(", ")}
                </div>
                <div className="post-at">
                    <PostBy authorNames={card.authorNames} date={card.date} />
                </div>
            </div>
        </Link>
    );
}

export default Card;
